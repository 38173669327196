import { mapState } from 'vuex';
import Vue from 'vue';
import VueGtag from 'vue-gtag';

import store from '@/store';
import router from '@/router';
import Menu from '@/components/_Template/Menu';
import Footer from '@/components/_Template/Footer';
import Alert from '@/components/Alert';
import Drawer from '@/components/Drawer';
import Loading from '@/containers/ContentLoad';
import PullRefresh from '@/components/PullRefresh';

import DesktopBridge from '@/plugins/desktop';
import AppBridge from '@/plugins/app';

require('./assets/css/veasy/reset.css');
require('./assets/css/veasy/grid.css');
require('./assets/css/veasy/fa.css');
require('./assets/css/veasy/help.css');
require('./assets/css/veasy/colors.css');
require('./assets/css/veasy/style.css');

Vue.use(
  VueGtag,
  {
    config: {
      id: 'UA-206072245-4',
      params: {
        send_page_view: true,
      },
    },
  },
  router
);

export default {
  data() {
    return {
      alert: false,
      alertMsg: '',
      alertButtonText: '',
      alertConfirmButtonText: '',
      alertCancelButtonText: '',
      alertType: 'alert',
      alertCallback: null,
      alertClose: false,
      openSignal: false,
      closeSignal: false,
      newOrder: false,
      changeClass: '',
      grayscale: false,
      windowWidth: 0,
      updateType: '',
      showFooter: true,
      refuseOrder: false,
      catalog: null,
      windowTitle: 'Olga',
      soundStatus: true,
    };
  },
  async mounted() {
    this.getIsMobile();
    this.getScreenWidth();
    this.getStoreConfigurations();
    this.initMarketing();
    this.setDesktopEnv();
    this.setUpdateListener();
    this.setEventListener();
    window.alert = this.showAlert;
    window.confirm = this.showConfirm;
    this.$nextTick(() => {
      if (!DesktopBridge.isDesktop()) window.document.title = this.windowTitle;

      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
      });
    });

    if (AppBridge.isApp()) {
      AppBridge.listen('keyboardDidShow', () => {
        this.showFooter = false;
      });

      AppBridge.listen('keyboardDidHide', () => {
        this.showFooter = true;
      });

      AppBridge.listen('reload', () => {
        window.location.reload();
      });
    }
  },
  methods: {
    setUpdateListener() {
      if (DesktopBridge.isDesktop()) {
        DesktopBridge.listen('update', () => {
          this.sendStoreAndSetUpdateType(true, 'update');
        });

        DesktopBridge.listen('clientUpdate', () => {
          this.sendStoreAndSetUpdateType(true, 'clientUpdate');
        });
      } else if (AppBridge.isApp()) {
        AppBridge.listen('update', () => {
          this.sendStoreAndSetUpdateType(true, 'update');
        });

        AppBridge.listen('clientUpdate', () => {
          this.sendStoreAndSetUpdateType(true, 'clientUpdate');
        });
      }
    },
    setEventListener() {
      if (DesktopBridge.isDesktop()) {
        DesktopBridge.listen('isFirstUse', () => {
          if (this.$store.state.route.name === 'login')
            router.replace({ name: 'signup' });
        });
      }
    },
    sendStoreAndSetUpdateType(status, updateType) {
      this.updateType = updateType;
      store.commit('store/setHasUpdate', status);
    },
    setUpdate(setOrUnset) {
      this.hasUpdate = setOrUnset;
    },
    setDesktopEnv() {},
    getStoreConfigurations(loading = true) {
      const self = this;
      if (self.$store.state.route.name !== 'login') {
        this.$store.dispatch('store/getConfigurations', loading);
      }
    },

    getIsMobile() {
      this.$store.dispatch('store/getIsMobile');
    },
    getScreenWidth() {
      this.$store.dispatch('store/getScreenWidth');
    },
    initMarketing() {
      this.setViewportMetaTag();
    },
    setFavIcon() {
      const t = document.getElementsByTagName('head')[0];
      const tfavicon = document.createElement('link');
      tfavicon.setAttribute('rel', 'shortcut icon');
      tfavicon.setAttribute('type', 'image/x-icon');
      tfavicon.setAttribute('href', 'https://olga.tech/favicon-olga.png');
      t.insertBefore(tfavicon, t.firstChild);
    },
    setViewportMetaTag() {
      const viewport = document.querySelector('meta[name=viewport]');
      viewport.setAttribute(
        'content',
        'user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height, target-densitydpi=device-dpi'
      );
    },
    showAlert(text, buttonText, secondsToClose = null, callback) {
      this.alertMsg = text;
      this.alertButtonText = buttonText;
      this.alert = !this.alert;
      this.alertType = 'alert';
      this.alertCallback = callback;
      if (!callback) {
        const promise = new Promise((resolve) => {
          this.alertCallback = resolve;
        });

        if (!secondsToClose) {
          return promise;
        }

        const timeout = new Promise((resolve) => {
          setTimeout(() => {
            this.alertClose = !this.alertClose;
            resolve();
          }, secondsToClose * 1000);
        });

        return Promise.race([promise, timeout]);
      }
    },
    showConfirm(text, callback, confirmText, cancelText) {
      this.alertMsg = text;
      this.alertConfirmButtonText = confirmText;
      this.alertCancelButtonText = cancelText;
      this.alert = !this.alert;
      this.alertType = 'confirm';
      this.alertCallback = callback;
      if (!callback) {
        return new Promise((resolve) => {
          this.alertCallback = resolve;
        });
      }
    },
    getNoTemplate(h) {
      return (
        <div>
          <router-view />
        </div>
      );
    },
    getTemplateBody(h) {
      const userName = this.oUser.name;
      return (
        <div class='h-min-100 h-max-100 bg-content'>
          <Loading
            show={this.loading}
            progress={this.loading ? this.loading * 1 : 0}
            template={!this.noTemplate}
          />

          {!this.noTemplate ? (
            <div>
              {!this.isMobile ? (
                <div>
                  <Menu oUser={this.oUser} isOpen={this.isOpen} />
                </div>
              ) : (
                !!this.showFooter && (
                  <div>
                    <Footer />
                  </div>
                )
              )}
            </div>
          ) : (
            <div></div>
          )}

          <div id='content' class={this.noTemplate ? 'no-template' : ''}>
            <PullRefresh />
            <router-view />
          </div>
        </div>
      );
    },
    getTemplate(h) {
      return (
        <div class='h-min-100'>
          {this.bHasTemplateBody === false
            ? this.getNoTemplate(h)
            : this.getTemplateBody(h)}
        </div>
      );
    },
    switchRefuse() {
      this.refuseOrder = !this.refuseOrder;
    },
  },
  computed: {
    ...mapState({
      storeConfiguration: (state) => state.store.configurations,
      isLogged: (state) => state.account.isLogged,
      loading: (state) => state.store.loading,
      oUser: (state) => state.account.oUser,
      bHasNoTemplate: (state) => state.route.meta.bHasNoTemplate,
      bHasTemplateBody: (state) => state.route.meta.bHasTemplateBody,
      scrolled: (state) => state.store.scrolled,
      isOpen: (state) => state.store.info.working_time.is_open,
      isMobile: (state) => state.store.isMobile,
      noTemplate: (state) => state.store.noTemplate,
      hasUpdate: (state) => state.store.hasUpdate,
    }),
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      const menu = document.getElementsByClassName('window-icon-bg');
      const maximize = document.querySelector(
        '.window-icon-bg .window-icon.window-max-restore'
      );

      this.getIsMobile();
      this.getScreenWidth();
    },
  },
  render(h) {
    if (this.isLogged) AppBridge.emit('onUnlockOrientation');

    return (
      <div id='app' ref='app'>
        {this.bHasNoTemplate === true
          ? this.getNoTemplate(h)
          : this.getTemplate(h)}

        {/* Alerta */}
        <Drawer
          open={this.alert}
          close={this.alertClose}
          title='Atenção'
          direction='top'
          height='auto'
          zIndex='3000'
          time='0.3'
          overflow='hidden'
          haveX={false}
        >
          <Alert
            msg={this.alertMsg}
            type={this.alertType}
            buttonText={this.alertButtonText}
            confirmButtonText={this.alertConfirmButtonText}
            cancelButtonText={this.alertCancelButtonText}
            cb={this.alertCallback}
            onCloseAlert={() => (this.alertClose = !this.alertClose)}
          />
        </Drawer>
      </div>
    );
  },
};
